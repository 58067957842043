












import {Component, Prop, Vue} from 'vue-property-decorator'
import Sidebar from '@/components/Sidebar.vue'

@Component({
  components: {Sidebar},
})
export default class AuthLayout extends Vue {
  authorized = false
  collapsed = true

  get viewClass() {
    return {
      'router-view--collapsed': this.collapsed,
    }
  }

  async mounted() {
    await this.$auth.authenticate()
    if (!this.$auth.isLogged) {
      this.$nav.replace('/sign-in')
    } else {
      this.authorized = true
    }
  }

  toggleEvent(menu: boolean) {
    this.collapsed = menu
  }
}
